<script>
import ModelForm from '@/components/Form/ModelForm.vue';
import ContractPositionDataService from "@/services/ContractPositionDataService";

export default {
  name: 'ContractPositionForm',
  extends: ModelForm,
  props: {
    apiSlug: { default: 'contract_positions' },
    vueSlug: { default: 'contract_position' }
  },
  data() {
    return {
      dataService: ContractPositionDataService,
      modelConfig: ContractPositionDataService.getEditorConfig(),
      currentEntity: ContractPositionDataService.getEntityDefinition(),
    }
  },
  validations() {
    return {
      currentEntity: this.dataService.getValidationRules()
    }
  },
  setup() {
    return ModelForm.setup();
  },
}
</script>
